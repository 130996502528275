@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url("./lib/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url("./lib/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}


body {
  --default-spacing: 24px;
  --default-card-spacing: 12px;
  --default-card-border-radius: 9px;
  --default-button-padding: 6px 24px;
  --default-front-page-spacing: 14vw;
  --font-enormous: clamp(14px, 6vw, 60px);
  --font-xxxlarge: clamp(10px, 3.5vw, 36px);
  --font-xxlarge: clamp(10px, 2.5vw, 30px);
  --font-xlarge: 24px;
  --font-large: 20px;
  --font-medium: 18px;
  --font-normal: 16px;
  --font-small: 14px;   /* body 3 */
  --color-complementary: #E69729;
  --color-accent: #2ECAFE;
  --color-accent-mkt: #1DC275;
  --color-primary: #2E62FE;
  --color-primary-light: #91BAF3;
  --color-yellow: #F0B61F;
  --color-background: #F8FBFF;
  --color-text-dark: #001964;
  --color-grigio-chiaro: #F4F4F4;
  --color-grigio-scuro: #C0C0C0;

  --pad-page-width: 30px;
  --pad-page-mobile-width: 15px;
  --max-header-width: 1440px;
  --max-page-width: 1196px;
  --max-page-pad-width: 1256px;

  height: 100%;
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.87);
}

#root {
  height: 100%;
}


h1 {
  font-size: 38px;
  font-weight: 700;
  margin-block-start: 0;
  margin-block-end: 0;
  color: var(--color-text-dark);
  white-space: nowrap;
  line-height: 120%;
}
h2 {
  font-size: 32px;
  font-weight: 700;
  margin-block-start: 0;
  margin-block-end: 0;
  color: var(--color-text-dark);
  white-space: nowrap;
  line-height: 120%;
}
h3 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  color: var(--color-text-dark);
}
h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

/*
input[type='text'] {
  text-transform: uppercase;
}

input[name='username'] {
  text-transform: none;
}

input[name='password'] {
  text-transform: none;
}

input::placeholder {
  text-transform: none;
}
*/

input[autocomplete="off"]::-webkit-contacts-auto-fill-button,
input[autocomplete="off"]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

/*
search
MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedStart

calendar
MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedEnd
 */


/* reduce autocomplete, radio, calendar height */
.MuiOutlinedInput-root {
  padding: 0 !important;
}


.MuiOutlinedInput-root .MuiOutlinedInput-input {
  padding: 10px 16px !important;
}
/* fix for calendar icon after above reduction */
.MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined.MuiInputAdornment-sizeMedium {
  margin-right: 14px !important;
}

/* fix for search icon after above reduction */
.MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-outlined.MuiInputAdornment-sizeMedium {
  margin-left: 8px !important;
  margin-right: 5px !important;
}

/* fix for search icon to increase input text */
.MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-outlined.MuiInputAdornment-sizeMedium ~ input  {
  padding: 10px 0 !important;
}

/* reduce select height */
.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 10px 10px !important;
}

/* reduce radiogroup right margin */
.MuiFormGroup-root.MuiFormGroup-row {
  padding: 0 0 0 14px;
}



.MuiToggleButtonGroup-grouped.Mui-selected {
  color: #fff !important;
  background-color: var(--color-primary) !important;
}


.react-tel-input {

  .form-control {
    width: 99% !important;
    height: 46px !important;
    padding: 16px 0 16px 58px;
    margin-right: 5px;
    font-family: Poppins;
    font-weight: 400;
    font-size: 1.1428571428571428rem !important;
    line-height: 1.4375em !important;
  }

  .form-control.invalid-number {
    border: 1px solid var(--color-complementary) !important;
  }

  .special-label {
    display: none !important;
  }
}

.pincode-input-text {
  color: #000;
  font-size: 24px;

  border: 1px solid #E1E1E1 !important;;
  width: 50px !important;
  height: 50px !important;
  margin: 0 6px !important;

  background-color: #FFF !important;
}

.pincode-input-text:disabled {
  background-color: #E1E1E1 !important;
}


@media screen and (max-width: 899px) {

  .responsive thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .responsive tr  {
  }

  .responsive td  {
    border-bottom: 1px solid #F4F4F4;
    display: block !important;
    text-align: right;

    > div {
      float: right;
    }
  }

  .responsive td:before {
    content: attr(data-label);
    float: left;
    font-size: 14px;
    text-transform: uppercase;
  }

  .responsive td:last-child {
    border-bottom: 0;
    clear: right;
    text-align: center;
  }



}